<template>
  <div class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:50%">
      <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6"
           v-bind:style="{cursor: this.cursor}">
        <div class="text-center mb-5">
          <img :src="'images/logo.png'" alt="Logo" class="mb-5" style="width:110px; height:90px;">
          <!--          <div class="text-900 text-3xl font-medium mb-3">Sistema de Acdministracion</div>-->
          <!--          <span class="text-600 font-medium line-height-3">Don't have an account?</span>-->
        </div>

        <div>
          <label for="email" class="block text-900 text-xl font-medium mb-2">Correo Electrónico</label>
          <InputText id="email"
                     v-model="email"
                     type="text"
                     :class='["w-full mb-3 class", errorMessage.length && "p-invalid" ]'
                     placeholder="Usuario"
                     style="padding:1rem;"/>

          <label for="password1" class="block text-900 font-medium text-xl mb-2">Contraseña</label>
          <Password id="password"
                    v-model="password"
                    placeholder="Contraseña"
                    class="w-full mb-3"
                    inputClass="w-full"
                    inputStyle="padding:1rem"
                    :feedback="false"
                    toggleMask
                    v-on:keyup.enter="onSubmit"/>

          <div class="field">
            <small id="email-help" class="p-error">{{ this.errorMessage }}</small>
          </div>
          <Button @click="onSubmit" label="Iniciar sesión" class="w-full p-3 text-xl"></button>

        </div>
        <div class="version">
          v{{ appVersion }}
        </div>
      </div>
    </div>
  </div>

  <Dialog id="welcome"
          header="Bienvenido"
          position="bottomright"
          v-model:visible="display"
  >
    <div>
      Usuario exitosamente creado. <br>
      Recuerde que antes de usarlo, deberá activarlo.<br>
      Por favor comuníquese con su administrador de sistema.
      <br><br>
      Gracias.
      <br><br>
    </div>
  </Dialog>

  <Dialog header="Usuario Bloqueado"
          v-model:visible="displayLockedMessage"
          position="topright"
          :showHeader="false"
          contentClass="locked"
          contentStyle="background-color: #ffc107;color: black; font-weight: bold; font-size: x-large; padding: 20px;">
    <div>Usuario bloqueado.</div>
    <div>Por favor comuníquese con administración.</div>
  </Dialog>

</template>

<script>
import AuthService from "@/service/backend/AuthService";
import VueCookies from "vue-cookies";
import EventBus from "@/app/AppEventBus";
import generalMixin from "@/mixins/generalMixin";

export default {
  mixins: [generalMixin],
  mounted() {
    const theme = !VueCookies.get('dark') ? 'bootstrap4-light-green' : 'bootstrap4-dark-green'
    EventBus.emit('theme-change', {theme: theme, dark: null});

    this.appVersion = "1.23.53";//process.env.VERSION;
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      errorMessage: '',
      checked: false,
      display: false,
      activeIndex: 0,
      loading2: false,
      displayLockedMessage: false,
      cursor: 'default',
      appVersion: null,
    }
  },
  computed: {},
  methods: {
    async onSubmit() {
      this.cursor = 'wait';
      if (this.validateForm) {
        this.loading2 = true;
        // Get token
        this.profile = await (new AuthService).login({email: this.email.trim(), password: this.password});
        if (this.profile.error) {
          if (Array.isArray(this.profile.error)) {
            // this.profile.error = "El usuario debe ser una dirección electrónica"
            this.loading2 = false;
          }
          this.errorMessage = this.profile.error;
          if (this.errorMessage === 'Usuario bloqueado') {
            this.displayLockedMessage = true;
          }
          this.loading2 = true;
        } else {
          this.$store.commit('setProfile', this.profile);
          this.$store.commit('setToken', this.profile.token);
          this.$store.commit('changeUserName', this.profile.name);
          this.$store.commit('setLevel', this.profile.level);
          // Currency

          this.cursor = 'default';
          await this.$router.push({name: 'dashboard'})
        }
        this.loading2 = false;
      }
    },

    validateForm() {
      if (!this.name.length) {
        this.errorMessage = "Por favor introduzca un nombre";
        return false;
      }
      if (!this.email.length) {
        this.errorMessage = "Por favor introduzca una dirección electrónica";
        return false;
      }
      if (!this.password.length) {
        this.errorMessage = "Por favor introduzca una contraseña";
        return false;
      }
      return true;
    },
  },
}
</script>

<style scoped lang="scss">
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}

Button {
  background: #004A49 !important;
  border-color: #004A49 !important;
  color: white !important;
}

Button :hover {
  border-color: #006669 !important;
  color: white !important;
}

.p-dialog .p-component .lockDialog {
  background: #ffc107 !important;
}

//::v-deep(.lockDialog .p-dialog-header) {
//  background: orangered !important;
//}

.lockDialog .p-dialog-header {
  background: #ffc107 !important;
}

.p-dialog-content {
  background: #ffc107 !important;
}

.customDialog {
  background: green;
}

.ui-dialog.customDialog .ui-dialog-content {
  padding: 0;
  margin: 20px 10px;
}

.ui-dialog-titlebar.ui-widget-header.customDialog {
  background: green;
  color: black;
  box-shadow: none;
}

.version {
  display: flex;
  justify-content: flex-end;
  margin-right: -10px;
  margin-bottom: -10px;
  margin-top: 5px;
  font-size: x-small;
  font-weight: bold;
}

:deep(.p-icon) {
  right:0 !important;
  margin-right:10px !important;
}

</style>