import BaseService from "./BaseService";

class AuthService extends BaseService {
  // Login
  async login(user) {
    try {
      const response = await this.axios.post('/auth/conalot/login/', {
        email: user.email,
        password: user.password
      });       
      return response.data;
    } catch (e) {
      console.log(e)
      return {error: e.response.data.message};
    }
  }
}

export default AuthService
